.small-text {
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  direction: rtl;
  width: 100%;
}

.liquid-row-context {
  line-height: normal;
  display: inline-block;
  padding: 0.2rem;
  width: 90%;
  overflow: hidden;
  direction: rtl;
  text-overflow: ellipsis;
}

.report-layout-table {
  --cell-width: 20rem;
  --cell-height: 4rem;

  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0;

  &__settings-icon {
    width: 100%;
  }

  &__heading-area,
  &__table {
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
    padding: 0;
  }

  &__table-wrapper {
    display: inline-block;
    position: relative;
  }

  &__heading-area {
    position: sticky;
    left: 0;
    z-index: 40;
  }

  &__data-area {
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: 20px;
    /* Firefox */
  }

  ::-webkit-scrollbar {
    height: 20px;
    background-color: #ddd;
  }

  ::-webkit-scrollbar-thumb {
    height: 20px;
    background-color: rgb(95, 95, 95);
  }

  &__head,
  &__body {
    display: contents;
  }

  &__row {
    display: flex;
    flex-flow: row;
    margin: 0;
    padding: 0;
  }

  &__selected-header {
    background-color: red;
  }

  &__scroll {
    margin: auto;
    width: 20rem;
    padding: 10px;
  }

  &__top-left-corner,
  &__column-heading,
  &__row-heading,
  &__cell {
    box-sizing: border-box;
    width: var(--cell-width);
    height: var(--cell-height);
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  &__double-height {
    height: calc(var(--cell-height) * 2) !important;
  }

  &__cell {
    padding: 0.2rem;
  }

  &__spreadsheet-heading {
    position: relative;
    background-color: #ddd;
    border-top: 0.1rem solid #eee;
    border-left: 0.1rem solid #eee;
    border-bottom: 0.1rem solid #bbb;
    border-right: 0.1rem solid #bbb;
    font: inherit;
    text-align: center;
    padding: 0 0.8rem;
    height: var(--cell-height);
    line-height: calc(var(--cell-height) - 0.2rem);
    overflow: visible;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    width: 100%;

    &__selected-header {
      background-color: red;
    }

    &--sub-object {
      margin-left: 2rem;
      width: calc(100% - 2rem);

      &::before {
        /* vertical connector */
        content: "";
        position: absolute;
        right: 100%;
        width: 1rem;
        height: var(--cell-height);
        top: 0rem;
        border-left: 0.1rem dotted black;
        display: block;
      }

      &::after {
        /* horizontal connector */
        content: "";
        position: absolute;
        right: 100%;
        width: 1rem;
        height: calc(var(--cell-height) / 2);
        top: 0rem;
        border-bottom: 0.1rem dotted black;
        display: block;
      }
    }

    &--last-sub-object {
      &::before {
        height: 2rem;
      }
    }

    &--has-drag-handles {
      background: #ddd
        url(data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M7%202a2%202%200%2010.001%204.001A2%202%200%20007%202zm0%206a2%202%200%2010.001%204.001A2%202%200%20007%208zm0%206a2%202%200%2010.001%204.001A2%202%200%20007%2014zm6-8a2%202%200%2010-.001-4.001A2%202%200%200013%206zm0%202a2%202%200%2010.001%204.001A2%202%200%200013%208zm0%206a2%202%200%2010.001%204.001A2%202%200%200013%2014z%22%20fill%3D%22%235C5F62%22%2F%3E%3C%2Fsvg%3E)
        no-repeat center;
      background-size: 12%;
      background-position: 5%;
    }
  }

  &__spreadsheet-heading-click-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  &__spreadsheet-heading-disclosure {
    position: absolute;
    right: 0;
    display: inline-flex;
    margin: 0.9rem 0;
    vertical-align: top;
    margin-right: 2rem;

    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  &__vertical-drag-marker {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 0;
    border: 0.1rem solid var(--p-interactive);
    transform: translateX(-0.1rem);
    left: -100rem; // off screen to start with;
    z-index: 40;
  }

  &__horizontal-drag-marker {
    position: absolute;
    left: 0;
    height: 0;
    width: 100%;
    border: 0.1rem solid var(--p-interactive);
    transform: translateY(calc(var(--cell-height) - 0.1rem));
    top: -100rem; // off screen to start with;
    z-index: 40;

    &--error {
      border-color: var(--p-interactive-critical);
    }
  }
}
