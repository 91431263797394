@import "@shopify/polaris/dist/styles.css";

/* patch up polaris rendering empty stack items */
.Polaris-Stack__Item:empty {
  display: none;
}

html {
  scroll-behavior: smooth;
}
