.active-field-list {
  &__field-item-list {
    margin-top: -2rem;
  }

  &__field-item {
    position: relative;
    padding-top: 2rem;
  }
}
