.file-listing-table {
  &__header-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 5.2rem;
  }

  &__row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-shadow: 0 0.1rem 0 0 var(--p-divider) inset;

    &--selected {
      background-color: var(--p-surface-selected);
    }
  }

  &__cell {
    flex: 1 0 0%;
    padding: 0.8rem 1.6rem;

    &--small {
      flex: 0 0 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
