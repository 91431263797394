.sticky-card {
  &__height-container {
    display: flex;
    flex-flow: column nowrap;
    max-height: calc(100vh - 4rem);

    .Polaris-Frame--hasTopBar & {
      max-height: calc(100vh - 10rem);
    }
  }

  &__header-container {
    flex: 0 0 auto;
  }

  &__scroll-container {
    overflow-y: auto;
    scroll-behavior: smooth;
  }
}
