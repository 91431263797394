.plan-feature-matrix {
  text-align: center;

  table {
    th {
      text-align: center;
    }

    td {
      text-align: center;
    }
  }
}
