.template-layout-grid {
  &__cell-contents-area {
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 3.6rem;
    margin: 0;
    padding: 0.7rem 1.6rem;
    border-radius: var(--p-border-radius-base);

    line-height: 1;
    text-align: center;

    -webkit-user-select: none;
    user-select: none;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;

    display: flex;
    width: 100%;

    transition: none;
    box-shadow: none;
    border: 1px solid var(--p-border-disabled);
    background: var(--p-surface-disabled);
    color: var(--p-text-disabled);
  }

  &__cell-contents-text {
    flex: 1 0 0%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__context-menu,
  &__aggregation {
    padding: 0px 1.6rem 1.6rem;
  }
}

/*.Polaris-Modal-CloseButton {
  margin-right: 10px;
}*/
