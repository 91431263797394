.template-editor {
  $this: &;

  &__field-area {
    margin-left: -1.6rem;
    margin-top: -2rem;
    display: flex;
    flex-flow: column nowrap;

    &--wide {
      flex-flow: row nowrap;
    }
  }

  &__field-browser {
    padding-left: 1.6rem;
    padding-top: 2rem;
    position: relative;
  }

  &__active-fields {
    padding-left: 1.6rem;
    padding-top: 2rem;
    position: relative;
  }

  &__field-area--wide #{$this}__field-browser {
    flex: 2 0 0%;
  }

  &__field-area--wide #{$this}__active-fields {
    flex: 1 1 0%;
    flex-flow: row nowrap;
    position: sticky;
    top: 0rem;
    bottom: 2rem;
    align-self: flex-start;

    .Polaris-Frame--hasTopBar & {
      top: 6rem;
    }
  }
}
